















































































































import Vue from "vue";

export default Vue.extend({
  name: "LandingPage",
  data: () => ({
    text: "TRANSFORM RAILWAY OPERATIONS FOR A SUSTAINABLE FUTURE",
    contactInfo: {
      name: null,
      company: null,
      phone: null,
      email: null,
      message: null,
    },
    sendTried: false,
    sendSuccessful: false,
    showNotification: false,
    notificationText: "",
     links: [
      { name: "about", link: "#about" },
      { name: "your benefits", link: "#your-benefits" },
      { name: "key technologies", link: "#key-technologies" },
      { name: "contact", link: "#contact" },
    ],
  }),
  computed: {
    year(): number {
      const date = new Date()
      const dateString = date.toISOString()
      const year = Number(dateString.slice(0,4))
      return Math.max(2022, year)
    },
  },
  methods: {
    async sendMessage() {
      const res = await fetch("https://s9ngccyne7.execute-api.eu-central-1.amazonaws.com/test/signup", {
        method: "post",
        body: JSON.stringify(this.contactInfo),
      })
        .then((r) => r.text())
        .then((r) => (this.sendSuccessful = true))
        .catch((r) => (this.sendSuccessful = false));
      this.sendTried = true;
    },
  },
});
