






































import Vue from "vue";
import InfoCard from "@/components/InfoCard.vue";

export default Vue.extend({
  name: "USP",
  components: { InfoCard },
  data: () => ({
    text: "TRANSFORM RAILWAY OPERATIONS FOR A SUSTAINABLE FUTURE",
  }),
});
