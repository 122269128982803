import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#333',
        accent: '#8BBAD8',
        error: '#FF5252',
        info: '#2196F3',
        success: '#A3D66F',
        warning: '#FFC107'
      },
    },
  },
});
