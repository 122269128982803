











import Vue from "vue";
import LandingPage from "@/components/LandingPage.vue";
import KeyTechnologies from "@/components/KeyTechnologies.vue";
import Benefits from "@/components/Benefits.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import About from "@/components/About.vue";
import Footer from "@/components/Footer.vue";
import ClientsAndPartners from "@/components/ClientsAndPartners.vue";

export default Vue.extend({
  name: "Home",

  components: {
    HelloWorld,
    LandingPage,
    KeyTechnologies,
    Benefits,
    About,
    Footer,
    ClientsAndPartners
  },
});
