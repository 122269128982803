



























import Vue from "vue";

export default Vue.extend({
  name: "LandingPage",
  data: () => ({
    text: "PROMISING NEW MIX DESIGNS WITH A SINGLE CLICK",
  }),
});
