




















import Vue from "vue";
import InfoCard from "@/components/InfoCard.vue";

export default Vue.extend({
  name: "LandingPage",
  components: { InfoCard },
  data: () => ({
    text: "TRANSFORM RAILWAY OPERATIONS FOR A SUSTAINABLE FUTURE",
    keyTechnologies: [
       {
        title: "Mathematical Optimization",
        icon: "mdi-math-integral",
        text: "",
      },
      {
        title: "Physical Models",
        icon: "mdi-beaker-outline",
        text: "",
      },
      {
        title: "Artificial Intelligence",
        icon: "mdi-memory",
        text: "",
      },
    ]
  }),
});
