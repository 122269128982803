














import Vue from "vue";
import InfoCard from "@/components/InfoCard.vue";

export default Vue.extend({
  name: "USP",
  components: { InfoCard },
  data: () => ({
    text: "TRANSFORM RAILWAY OPERATIONS FOR A SUSTAINABLE FUTURE",
    infoCards: [
      {
        title: "Software-as-a-Service",
        icon: "mdi-cloud-outline",
        text: "Fully managed software solution hosted in the cloud for maximum security and performance.",
      },
      {
        title: "Enterprise Integrations",
        icon: "mdi-google-spreadsheet",
        text: "We provide customized data integrations, depending on your infrastructure. This includes API interfaces and excel integration for simple drag-and-drop upload of your existing data.",
      },
      {
        title: "Domain Experts",
        icon: "mdi-account-hard-hat",
        text: "We are experts in material science and mathematical optimization with a combinded experience of 50+ years in the industry.",
      },
      {
        title: "Sustainable Concrete",
        icon: "mdi-leaf",
        text: "Significant CO2 reductions through the need of less cement or the use of new materials",
      },
      {
        title: "Faster Research",
        icon: "mdi-speedometer",
        text: "Our AI can significantly reduce the number of iterations necessary to develop a new mix design.",
      },

      {
        title: "Better Results",
        icon: "mdi-chevron-triple-up",
        text: "Our new optimization approach results in better reproducible concrete mixtures of higher quality, whose properties have a high stability.",
      },
    ],
  }),
});
