


























import Vue from "vue";
import InfoCard from "@/components/InfoCard.vue";

export default Vue.extend({
  name: "ClientsAndPartners",
  components: { InfoCard },
  data: () => ({
    clientsAndPartners: [
      {
        name: "Climate KIC",
        link: "",
        icon: `climate_kic_small_grayscale.png`,
      },
      {
        name: "Creative Destruction Lab",
        link: "",
        icon: `creative_destruction_lab.png`,
      },
      {
        name: "Clean Cities ClimAccelerator",
        link: "",
        icon: `clean_cities_accelerator.png`,
      },
      {
        name: "Mass Challenge",
        link: "",
        icon: `mass_challenge.png`,
        height: "60"
      },
    ],
  }),
});
