















































































import Vue from "vue";
import Footer from "@/components/Footer.vue";

export default Vue.extend({
  name: "App",
 components: {
    Footer,
  },
  data: () => ({
    //
    cookieSnackbar: true,
    isMobile: false,
    drawer: null,
    links: [
      { name: "about", link: "#about" },
      { name: "your benefits", link: "#your-benefits" },
      { name: "key technologies", link: "#key-technologies" },
      { name: "contact", link: "#contact" },
    ],
  }),
  // beforeDestroy() {
  //   if (typeof window === "undefined") return;
  //   // @ts-ignore
  //   window.removeEventListener("resize", this.onResize, { passive: true });
  // },

  // mounted() {
  //   this.onResize();

  //   window.addEventListener("resize", this.onResize, { passive: true });
  // },

  // methods: {
  //   onResize() {
  //     this.isMobile = window.innerWidth < 600;
  //   },
  // },
});
