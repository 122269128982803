




















import Vue from "vue";

export default Vue.extend({
  name: "InfoCard",
  props: {
    infoCard: { type: Object, required: true },
    column: { type: Boolean, default: false },
  },
  data: () => ({
    text: "TRANSFORM RAILWAY OPERATIONS FOR A SUSTAINABLE FUTURE",
  }),
});
